<template>
  <div class="container-fluid p-4">
    <div class="row text-center">
      <div class="col-12 mb-2">
        <h1 class="mb-0">{{ meta.title }}</h1>
        {{ new Date().toLocaleString("tr", { timeZone: "Europe/Istanbul" }) }}
      </div>
      <div class="col-12 mb-2 d-print-none">
        <button class="btn btn-primary" onclick="window.print()">Yazdır</button>
      </div>
      <div
        class="col mb-2"
        v-for="(filter, index) in meta.filters"
        v-bind:key="index"
      >
        <b>{{ filter.label }}:</b> {{ filter.value }}
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body p-0">
            <table class="table" v-if="type === 1">
              <thead>
                <tr>
                  <th v-for="(header, index) in headers" v-bind:key="index">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" v-bind:key="index">
                  <td>{{ item.reservation_id}}</td>
                  <td>{{ item.agency}}</td>
                  <td>{{ item.hotel }}</td>
                  <td>{{ item.room_count }}</td>
                  <td>{{ item.confirme_number }}</td>
                  <td>{{ item.buy_price }} {{ item.buy_price_symbol }}</td>
                  <td>{{ item.sell_price }} {{ item.sell_price_symbol }}</td>
                  <td>{{ item.first_date }} / {{ item.last_date }}</td>
                  <td>{{ calculateNight(item.first_date, item.last_date, item.room_count) }}</td>
                  <td>{{ (item.sell_price * calculateNight(item.first_date, item.last_date, item.room_count)).toFixed(2) + " " + item.sell_price_symbol }}</td>
                  <td>{{ (item.buy_price * calculateNight(item.first_date, item.last_date, item.room_count)).toFixed(2) + " " + item.buy_price_symbol }}</td>
                </tr>
                <tr v-for="(buy_total, index) in buy_totals" v-bind:key="index">
                  <td colspan="8"></td>
                  <td colspan="2" style="text-align: end;">Alış Toplam:</td>
                  <td colspan="1" style="text-align: start;">{{buy_total.total}} {{buy_total.symbol}}</td>
                </tr>
                <tr v-for="(sell_total, index) in sell_totals" v-bind:key="index">
                  <td colspan="8"></td>
                  <td colspan="2" style="text-align: end;">Satış Toplam:</td>
                  <td colspan="1" style="text-align: start;">{{sell_total.total}} {{sell_total.symbol}}</td>
                </tr>
              </tbody>
            </table>
            <table class="table" v-if="type === 7">
              <thead>
                <tr>
                  <th v-for="(header, index) in headers" v-bind:key="index">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(group, index) in formattedItems" v-bind:key="index">
                  <td>{{ group.reservation_id }}</td>
                  <td>{{ group.agency }}</td>
                  <td>{{ group.dateRange }}</td> <!-- Tarih aralığını gösterir -->
                  <td>{{ group.plates }}</td>
                  <td>
                    {{ Object.entries(group.total_buy_price).map(([symbol, total]) => `${total} ${symbol}`).join(', ') }}
                  </td>
                  <td>
                    {{ Object.entries(group.total_sell_price).map(([symbol, total]) => `${total} ${symbol}`).join(', ') }}
                  </td>
                </tr>
                <!-- Toplam Fiyatlar İçin Ekstra Satır -->
                <tr v-for="(total, symbol) in totalPrices" :key="symbol">
                  <td colspan="3"></td>
                  <td>Total for {{ symbol }}</td>
                  <td>{{ total.buy_price }} {{ symbol }}</td>
                  <td>{{ total.sell_price }} {{ symbol }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table table-striped" v-if="type != 1 && type != 7">
              <thead>
                <tr>
                  <th v-for="(header, index) in headers" v-bind:key="index">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" v-bind:key="index">
                  <td v-for="(value, index) in item" v-bind:key="index">
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

const token = JSON.parse(localStorage.getItem("user")).token;

export default {
  data() {
    return {
      meta: {
         title: "",
         filters: [],
       },
       headers: [],
       items: [],
       buy_totals: [],
       sell_totals: [],
       type: 0,
    };
  },
  mounted: function () {
    this.getData();
  },
  computed: {
    formattedItems() {
      return Object.values(this.groupedItems()).map(group => {
        // Dates setini diziye çevirip sıralıyoruz
        const sortedDates = Array.from(group.dates).sort();

        // Tarihleri istediğin formata çevirmek
        const formattedStartDate = this.formatDate(sortedDates[0]);
        const formattedEndDate = this.formatDate(sortedDates[sortedDates.length - 1]);

        const dateRange = `${formattedStartDate} / ${formattedEndDate}`;

        // Plates setini diziye çeviriyoruz
        const uniquePlates = Array.from(group.plates).join(', ');

        return {
          ...group,
          dateRange, // Tarih aralığını ekledik
          plates: uniquePlates, // Gruplanmış plaka değerleri
        };
      });
    },

    totalPrices() {
      return this.items.reduce((acc, item) => {
        // Toplam buy_price ve sell_price için price_symbol bazında gruplama
        if (!acc[item.buy_price_symbol]) {
          acc[item.buy_price_symbol] = { buy_price: 0, sell_price: 0 };
        }

        acc[item.buy_price_symbol].buy_price += item.buy_price;
        acc[item.buy_price_symbol].sell_price += item.sell_price;

        return acc;
      }, {});
    }
  },
  methods: {
    calculateNight(firstDate, lastDate, room_count) {
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDateObj = new Date(firstDate);
      const lastDateObj = new Date(lastDate);

      const night = Math.round(Math.abs((firstDateObj - lastDateObj) / oneDay));
      const total = (night * room_count);
      return total;
    },
    getData: function () {
      const type = new URLSearchParams(window.location.search).get('type');
      const start_date = new URLSearchParams(window.location.search).get('start_date');
      const end_date = new URLSearchParams(window.location.search).get('end_date');
      const hotel = new URLSearchParams(window.location.search).get('hotel');
      const agency = new URLSearchParams(window.location.search).get('agency');
      const api_url = process.env.VUE_APP_BASEURL + `/reports/result?type=${type}&start_date=${start_date}&end_date=${end_date}&hotel=${hotel}&agency=${agency}`;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.meta.title = response.data?.title;
          this.meta.filters = response.data?.filters;
          this.headers = response.data?.headers;
          this.items = response.data?.items;
          this.buy_totals = response.data?.buy_totals;
          this.sell_totals = response.data?.sell_totals;
          this.type = response.data?.type;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    groupedItems() {
      return this.items.reduce((acc, item) => {
        if (!acc[item.reservation_id]) {
          acc[item.reservation_id] = {
            reservation_id: item.reservation_id,
            agency: item.agency,
            dates: new Set(), // Tekrar eden tarihleri engelle
            plates: new Set(), // Tekrar eden plakaları engelle
            total_buy_price: {}, // Symbol'e göre buy_price toplama
            total_sell_price: {}, // Symbol'e göre sell_price toplama
            buy_price_symbol: item.buy_price_symbol,
            sell_price_symbol: item.sell_price_symbol,
          };
        }

        // Tarih ve plaka tekrarlarını kontrol et
        acc[item.reservation_id].dates.add(item.date);
        acc[item.reservation_id].plates.add(item.plate);

        // Buy_price toplamlarını symbol'e göre ekle
        if (!acc[item.reservation_id].total_buy_price[item.buy_price_symbol]) {
          acc[item.reservation_id].total_buy_price[item.buy_price_symbol] = 0;
        }
        acc[item.reservation_id].total_buy_price[item.buy_price_symbol] += item.buy_price;

        // Sell_price toplamlarını symbol'e göre ekle
        if (!acc[item.reservation_id].total_sell_price[item.sell_price_symbol]) {
          acc[item.reservation_id].total_sell_price[item.sell_price_symbol] = 0;
        }
        acc[item.reservation_id].total_sell_price[item.sell_price_symbol] += item.sell_price;

        return acc;
      }, {});
    },
    formatDate(date) {
      try {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate.getTime())) {
          throw new Error('Invalid Date');
        }
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Intl.DateTimeFormat('tr-TR', options).format(parsedDate);
      } catch (error) {
        console.error('Date formatting error:', error, 'Date:', date);
        return date; // Fallback to original date if formatting fails
      }
    },
  },
};
</script>

<style scoped>
@media print {
  @page {
    size: a4 landscape;
  }
}
</style>